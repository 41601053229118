  // 详情弹出框
<template>
  <base-dialog :visible.sync="visibleState" :showFooter="false" title="监测信息详情">
    <titleTemplate :name="name" />
    <formInfo
      :count="count"
      :componentList="formInfoConfig"
      :model="detailInfo"
    />
    <template slot="footer">
      <base-button label="关闭" @click="visibleState = false" type="default" />
    </template>
  </base-dialog>
</template>
<script>
import titleTemplate from '@/pages/business/components/titleTemplate.vue'
import BaseDialog from '@/components/common/base-dialog/base-dialog.vue'
import FormInfo from '@/components/packages/form-info/form-info.vue'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import { formInfoConfig } from '../utils/detalis-config'
import { riskfxAPi } from '@/api/riskApi'

export default {
  props: {
    visible: Boolean,
    name: String,
    detailInfo: Object
  },
  data () {
    return {
      count: 0,
      isReader: false
    }
  },
  components: { titleTemplate, BaseDialog, FormInfo, BaseButton },
  computed: {
    formInfoConfig () {
      return formInfoConfig(this)
    },
    visibleState: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    },
    api () {
      return riskfxAPi
    }
  },
  watch: {
    visible (val) {
      if (val) {
        this.detail()
      }
    }
  },
  methods: {
    // 详情表格数据
    detail () {
      this.$nextTick(() => {
        this.count++
      })
      // 阅读状态
      if (this.detailInfo.readStatus === '未读') {
        this.api
          .updataReadStatus({ keyId: this.detailInfo.keyId })
          .then((res) => {
            if (res.success) {
              this.isReader = true
            }
          })
      }
    }
  }
}
</script>
