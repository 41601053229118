import { formatDate } from '@/utils/auth/common'
// 详情form
export const formInfoConfig = () => {
  const span = 12
  return [
    {
      label: '业务编号',
      prop: 'businessNo',
      span
    },
    {
      label: '风险类型',
      prop: 'riskTypeName',
      span
    },
    {
      label: '预警条件',
      prop: 'warningConditions',
      span
    },
    {
      label: '监测值',
      prop: 'checkValue',
      span
    },
    {
      label: '监测时间',
      prop: 'checkTime',
      formatter: row => {
        return formatDate(row.checkTime, 'YY-MM-DD')
      },
      span
    },
    {
      label: '提醒人员',
      prop: 'businessManager',
      span
    },
    {
      label: '阅读状态',
      prop: 'readStatus',
      span
    }
  ]
}
