import http from '@/utils/http'
import Rest from '@/utils/http/Rest'

export default class Risk extends Rest {
  /**
   * 闭环账户管理分页查询
   * @param {*} params 请求参数
   * @returns {*|AxiosPromise} 响应
   */
  account (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/account`,
      params
    })
  }

  /**
   * 闭环账户管理分页查询
   * @param {*} data 请求参数
   * @returns {*|AxiosPromise} 响应
   */
  accountLock (data) {
    return http({
      method: 'PUT',
      url: `/api/${this.subSys}/${this.module}/account/lock`,
      data
    })
  }

  /**
   * 闭环账户管理分页查询
   * @param {*} data 请求参数
   * @returns {*|AxiosPromise} 响应
   */
  accountUnlock (data) {
    return http({
      method: 'PUT',
      url: `/api/${this.subSys}/${this.module}/account/unlock`,
      data
    })
  }

  /**
   * 回函文件上传与删除
   * @param {*} data 请求参数
   * @returns {*|AxiosPromise} 响应
   */
  accountFileEdit (data) {
    return http({
      showLoading: true,
      method: 'PUT',
      url: `/api/${this.subSys}/${this.module}/file/edit`,
      data
    })
  }

  /**
   * 获取风险类型下拉列表
   */
  getRiskTypeSelectData () {
    return http({
      url: `/api/${this.subSys}/${this.module}/type`,
      method: 'get'
    })
  }

  /**
   * 风险监控分页查询
   * @param params
   * @returns {AxiosPromise}
   */
  setPage (params) {
    return http({
      showLoading: true,
      url: `/api/${this.subSys}/${this.module}/page`,
      method: 'get',
      params
    })
  }

  /**
   * 更新为阅读状态
   * @param data 参数
   * @returns {AxiosPromise}
   */
  updataReadStatus (data) {
    return http({
      method: 'PUT',
      url: `/api/${this.subSys}/${this.module}/read`,
      data
    })
  }

  /**
   * 闭环账户合同信息查询
   * @param params 参数
   * @returns {AxiosPromise}
   */
  contract (params) {
    return http({
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/contract`,
      params
    })
  }
}
